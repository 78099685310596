import { useState, useEffect, useRef } from "react";
import "../styles/MultiItemSlider.css";

const MultiItemSlider = ({ images }) => {
  const [index, setIndex] = useState(0);
  const [imageWidth, setImageWidth] = useState(0);
  const sliderRef = useRef(null);

  // Update image width dynamically
  useEffect(() => {
    const updateImageWidth = () => {
      if (sliderRef.current) {
        setImageWidth(sliderRef.current.clientWidth);
      }
    };

    updateImageWidth();
    window.addEventListener("resize", updateImageWidth);
    return () => window.removeEventListener("resize", updateImageWidth);
  }, []);

  // Auto-scroll every 3 seconds
  useEffect(() => {
    if (!images || images.length === 0) return;

    const autoScroll = setInterval(() => {
      nextSlide();
    }, 3000);

    return () => clearInterval(autoScroll);
  }, [index, images]);

  const nextSlide = () => {
    if (!images || images.length === 0) return;
    setIndex(index >= images.length - 1 ? 0 : index + 1);
  };

  const prevSlide = () => {
    if (!images || images.length === 0) return;
    setIndex(index === 0 ? images.length - 1 : index - 1);
  };

  return (
    <div className="slider-container">
      <button className="prev" onClick={prevSlide}>&#10094;</button>
      <div className="slider-wrapper" ref={sliderRef}>
        <div
          className="slider"
          style={{ transform: `translateX(-${index * imageWidth}px)` }}
        >
          {images && images.map((src, i) => (
            <img key={i} src={src} alt={`Slide ${i}`} className="slide-image" />
          ))}
        </div>
      </div>
      <button className="next" onClick={nextSlide}>&#10095;</button>
    </div>
  );
};

export default MultiItemSlider;
