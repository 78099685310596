import React, { useEffect, useState } from "react";
import "../styles/home.css";
import logo from "../assets/images/LBS_logo_W.png";
import LazyVideo from "../components/LazyVideo";

const Home = () => {
  const [bannerVideoUrl, setBannerVideoUrl] = useState(null);
  const [videos, setVideos] = useState([]);
  const [showBannerVideo, setShowBannerVideo] = useState(false);
  const [showTransition, setShowTransition] = useState(false);

  // Fetch banner video if applicable
  useEffect(() => {
    fetch("https://littleblackstarfx.com/admin/api/mainbanner.php", {
      headers: {
        "Authorization": "Bearer saka8f459fb743e8bc8d5e249ce2d8f3cfdar1vifnthan8c89e67b1d4a5f4a6b8c9d0e1aravinthan",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success" && data.data && data.data.video) {
          setBannerVideoUrl(data.data.video);
        } else {
          console.error("Unexpected banner video API response:", data);
        }
      })
      .catch((error) => console.error("Error fetching banner video URL:", error));
  }, []);

  // Fetch alternating video sections from API
  useEffect(() => {
    fetch("https://littleblackstarfx.com/admin/api/homevideos.php", {
      headers: {
        "Authorization": "Bearer saka8f459fb743e8bc8d5e249ce2d8f3cfdar1vifnthan8c89e67b1d4a5f4a6b8c9d0e1aravinthan",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success" && Array.isArray(data.data)) {
          setVideos(data.data);
        } else {
          console.error("Unexpected videos API response:", data);
        }
      })
      .catch((error) => console.error("Error fetching videos:", error));
  }, []);

  // Common functionality: in-view animations and delayed effects
  useEffect(() => {
    const sections = document.querySelectorAll("section");
    const observerOptions = { threshold: 0.2 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("in-view");
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));

    const videoTimer = setTimeout(() => setShowBannerVideo(true), 2000);
    const transitionTimer = setTimeout(() => setShowTransition(true), 5000);

    return () => {
      observer.disconnect();
      clearTimeout(videoTimer);
      clearTimeout(transitionTimer);
    };
  }, []);

  useEffect(() => {
    const sections = document.querySelectorAll("section");
    const observerOptions = { threshold: 0.2 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("in-view");
        }
      });
    }, observerOptions);
  
    // Observe each section
    sections.forEach((section) => observer.observe(section));
  
    return () => {
      observer.disconnect();
    };
  }, [videos]); // Re-run this effect when videos update
  useEffect(() => {
    document.title = "Little Black Star Visual Effects";
  }, []);

  return (
    <div className="home-page">
      {/* Banner Section */}
      <section className="main-bannerh fade">
        {showBannerVideo && bannerVideoUrl && (
          <LazyVideo src={bannerVideoUrl} type="video/mp4" className="banner-video" />
        )}
        <div className={`banner-content ${showTransition ? "transitioned" : ""}`}>
          <img src={logo} alt="LBS" className="banner-logo" />
          {!showTransition && <h3 className="p-0 m-0">Creating Worlds Beyond Imagination</h3>}
        </div>
      </section>
    
      {/* Alternating Video Sections */}
      {videos.map((video, index) => {
  const slideClass = index % 2 === 0 ? "slide-right" : "slide-left";
  return (
    <section key={video.id} className={`container-fluid ${slideClass} mb-0 p-0 g-0`}>
      <div className="row g-0">
        <div className="item col-12"> {/* Simplified layout for testing */}
          <div className="vimeo-section">
            <iframe
           src={`${video.video}?autoplay=1&muted=1&loop=1&background=1&controls=0`}
           allow="autoplay; fullscreen"
           title={`LBS Video ${video.id}`}
            ></iframe>
            {video.website && video.website.trim() && (
              <div className="clickHere">
                <a href={video.website} target="_blank" rel="noopener noreferrer">
                  Click here to explore
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
})}

 
   
    </div>
  );
};

export default Home;
