// LazyVideo.jsx
import React, { useRef, useEffect, useState } from "react";

const LazyVideo = ({ src, type, className }) => {
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the video is visible
    );

    if (videoRef.current) observer.observe(videoRef.current);

    return () => {
      if (videoRef.current) observer.unobserve(videoRef.current);
    };
  }, []);

  return (
    <div ref={videoRef} className="video-container">
      {isVisible ? (
        <video
          className={className}
          autoPlay
          muted
          loop
          playsInline
          src={src}
          type={type}
        ></video>
      ) : (
        <div className="video-placeholder">Loading video...</div>
      )}
    </div>
  );
};

export default LazyVideo;
