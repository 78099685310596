// components/Header.jsx
import React from "react";
import { Link,useLocation  } from 'react-router-dom';
import FullscreenMenu from "./FullscreenMenu";
import "../styles/header.css";
import "../styles/global.css";
import "../styles/grid.css";

 
import logo from "../assets/images/LBS_logo_W.png"; 
 
const Header = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const isHome = location.pathname === "/home";
  const isSquad = location.pathname === "/Squad";
  return (
    <header className="site-header">
     
  
      {(!isHomePage && !isHome && !isSquad) && (
        <div className="logo">
           <Link  to='/'>
        <img src={logo} alt="LBS Logo" />
        </Link>
        </div>
      )}

      {/* Include the FullscreenMenu */}
      <FullscreenMenu />
    </header>
  );
};

export default Header;
