// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-container {
  display: flex;
  overflow: hidden;
  width: 100%;
  /* Adjust to your preferred height */
  margin: auto;
  position: relative;
}

.carousel-track {
  display: flex;
  will-change: transform;
}

.carousel-item {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.carousel-item img {
  width: 100%;
max-height: 240px;
  object-fit: cover;
  object-position: top;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ImageCarousel.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,WAAW;EACX,oCAAoC;EACpC,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,WAAW;AACb,iBAAiB;EACf,iBAAiB;EACjB,oBAAoB;AACtB","sourcesContent":[".carousel-container {\r\n  display: flex;\r\n  overflow: hidden;\r\n  width: 100%;\r\n  /* Adjust to your preferred height */\r\n  margin: auto;\r\n  position: relative;\r\n}\r\n\r\n.carousel-track {\r\n  display: flex;\r\n  will-change: transform;\r\n}\r\n\r\n.carousel-item {\r\n  flex-shrink: 0;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  padding: 10px;\r\n}\r\n\r\n.carousel-item img {\r\n  width: 100%;\r\nmax-height: 240px;\r\n  object-fit: cover;\r\n  object-position: top;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
