import React, { useState } from "react";
import { Link  } from "react-router-dom";
import logob from "../assets/images/LBS_logo_B.png"; 
import TPN2 from "../assets/images/TPN2.png"; 
import star from "../assets/images/star.png"; 
 
 
import "../styles/menu.css";
import "../styles/grid.css";
 
 

const FullscreenMenu = () => {
 


  const [isOpen, setIsOpen] = useState(false);
  const handleMenuOpen = () => {
    setIsOpen(true);
    setTimeout(() => {
      document.querySelectorAll(".nav li").forEach((item) => {
        item.classList.add("show");
      });
    }, 500); // Delay to sync with the star animation
  };

  const handleMenuClose = () => {
    setIsOpen(false);
    document.querySelectorAll(".nav li").forEach((item) => {
      item.classList.remove("show");
    });
  };
 

  return (
    <>
      {/* Toggle Menu Button */}
      <button
        className={`menu-btn ${isOpen ? "hide" : ""}`}
        onClick={handleMenuOpen}
      >
        ☰
      </button>

      {/* Close Button */}
      <button
        className={`close-btn ${isOpen ? "" : "hide"}`}
        onClick={handleMenuClose}
      >
        ✕
      </button>

      {/* Fullscreen Menu */}
   
     {/* Fullscreen Menu */}
   
           <nav className={`fullscreen-menu ${isOpen ? "open" : ""}`}>

           
           <img
          src={star}
          alt="star"
          className={`star-img ${isOpen ? "animate" : ""}`}
        />
         
             {/* Logo at the Top */}
              {/* <div className="menu-logo-top">
             <img src={logob} alt="Logo" /> 
             </div>*/}
     
             {/* Menu Items */}
             
         
             <ul className="nav">
               <li>
                 <Link to="/home" onClick={() => setIsOpen(false)}>
                   Home
                 </Link>
               </li>
               <li>
                 <Link to="/projects" onClick={() => setIsOpen(false)}>
                 Projects
                 </Link>
               </li>
               <li>
                 <Link to="/stories" onClick={() => setIsOpen(false)}>
                   Stories
                 </Link>
               </li>
               <li>
                 <Link to="/about-us" onClick={() => setIsOpen(false)}>
                   About Us
                 </Link>
               </li>

               <li>
                 <Link to="/Squad" onClick={() => setIsOpen(false)}>
                 The Squad
                 </Link>
               </li>
               
               <li>
                 <Link to="/Lets-Connect" onClick={() => setIsOpen(false)}>
                 Let’s Connect
                 </Link>
               </li>
             </ul>
     
         
          
        {/* Logo in the Footer */}
    
        <div className="container m-0 p-0">
        <div className="row">
      
        <div className=" col-xl-12 col-md-12  col-12       text_center">
           
           <img src={logob} alt="LBS Logo" className="flogo2" /><br></br>
         <p><strong> CERTIFIED BY TRUSTED PARTNER NETWORK</strong></p>
           
         </div>
           
        
          
        </div>
        <div className="row align-items-center flex-wrap mb-3 d-flex justify-content-center ">
       
 

      

          {/* Career Email */}
          <div className="col-xl-3 col-md-12 col-12  footer_link  text-center text-md-start mb-3 mb-md-0">
            <p>
            <Link to="mailto:career@littleblackstarfx.com">
              <strong>Careers</strong> <br />
        
                career@littleblackstarfx.com
              </Link>
            </p>
          </div>

          {/* VFX Email */}
          <div className="col-xl-6 col-md-12 col-12 footer_link  text-center text-md-start mb-3 mb-md-0">
            <p>
            <Link to="mailto:director@littleblackstarfx.com">
              <strong> Feature Films, Commercials & Gaming Trailers </strong> <br />
              
              director@littleblackstarfx.com
              </Link>
            </p>
          </div>

          {/* Investor Relations */}
          <div className="col-xl-3 col-md-12  footer_link  col-12 text-center text-md-start mb-3 mb-md-0">
            <p>
            <Link to="mailto:director@littleblackstarfx.com">
              <strong>Investor Relations</strong> <br />
     
              director@littleblackstarfx.com
              </Link>
            </p>
          </div>
           
             
        </div>

 

       
      </div>
  
  
      </nav>
     
    </>
  );
};

export default FullscreenMenu;
