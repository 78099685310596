// components/Footer.jsx
import React from "react";

import flogo from "../assets/images/LBS_logo_W.png";
import TPN1w from "../assets/images/TPN1w.png";
import instagram from "../assets/images/instagram.png";
import linkedin from "../assets/images/linkedin.png";
import vimeo from "../assets/images/vimeo.png";
import facebook from "../assets/images/facebook.png";
import twitter from "../assets/images/twitter.png";

const Footer = () => {
  return (
    <footer className="site-footer footer_con py-5 my-5 ">
      <div className="container-fluid m-0 p-0">
        <div className="row align-items-center flex-wrap justify-content-center ">
          {/* Logo Section */}
          <div className=" col-xxl-12  col-xl-12 col-lg-12  col-lg-12 col-md-12 col-sm-12 text-md-center  col-12     mb-3  ">
            <img src={flogo} alt="LBS Logo" className="flogo" />
            <img src={TPN1w} alt="LBS Logo" className="ftpn1" />
            </div>
            

          {/* Social Media Icons */}
         

          {/* Address */}
          <div className="col-xxl-3  col-xl-3  col-lg-12  col-md-12 col-12 text-center   mb-3 mb-md-0">
            <p>
            <span className="foot_tit">Andhra Pradesh, India</span> <br />
            <span className="foot_des"> IT Park, Autonagar, Mangalagiri, 522503</span> 
            </p>
          </div>

          {/* Career Email */}
          <div className="col-xxl-3 col-xl-3 col-lg-12  col-md-12 col-12  footer-link text-center  mb-3 mb-md-0">
            <p className="  ">
            <a href="mailto:career@littleblackstarfx.com">
            <span className="foot_tit">Careers</span> <br />
        
            <span className="foot_des"> career@littleblackstarfx.com</span> 
              </a>
            </p>
          </div>

          {/* VFX Email */}
          <div className="col-xxl-3 col-xl-3 col-lg-12  col-md-12 col-12 footer-link  text-center  mb-3 mb-md-0">
            <p>
            <a href="mailto:director@littleblackstarfx.com">
            <span className="foot_tit"> Feature Films, Commercials & <br></br>Gaming Trailers </span> <br />
              
            <span className="foot_des"> director@littleblackstarfx.com</span> 
              </a>
            </p>
          </div>

          {/* Investor Relations */}
          <div className="col-xxl-3 col-xl-3  col-lg-12  col-md-12   col-12 footer-link  text-center  mb-3 m-0 p-0">
            <p>
            <a href="mailto:director@littleblackstarfx.com">
              <span className="foot_tit">Investor Relations</span> <br />
     
              <span className="foot_des">director@littleblackstarfx.com</span>  
              </a>
            </p>
          </div>
        </div>

 

        <div className="row mt-5">
          <div className=" col-12  ">
           
          
          <a
              href="https://www.instagram.com/littleblackstarvfx/?hl=en"
              rel="noreferrer"
              target="_blank"
              className="mx-2"
            >
              <img src={instagram} alt="Instagram" className="smicon" />
            </a>
            <a
              href="https://www.linkedin.com/company/littleblackstarvfx/"
              rel="noreferrer"
              target="_blank"
              className="mx-2"
            >
              <img src={linkedin} alt="LinkedIn" className="smicon" />
            </a>
            <a
              href="https://www.facebook.com/littleblackstarvfx/"
              rel="noreferrer"
              target="_blank"
              className="mx-2"
            >
              <img src={facebook} alt="Facebook" className="smicon" />
            </a>
            <a
              href="https://x.com/lbsvfx"
              rel="noreferrer"
              target="_blank"
              className="mx-2"
            >
              <img src={twitter} alt="twitter" className="smicon " />
            </a>
            <a
              href="https://vimeo.com/user221446330"
              rel="noreferrer"
              target="_blank"
              className="mx-2"
            >
              <img src={vimeo} alt="vimeo" className="smicon " />
            </a>
            <p> &copy; 2024 LBS. All Rights Reserved.</p> 
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
