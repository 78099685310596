import React, { useEffect, useState } from "react";
import "../styles/form.css";
 

const GetInTouch = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    const newErrors = {};
    let isValid = true;

    if (!/^[a-zA-Z\s]+$/.test(formData.firstName.trim())) {
      newErrors.firstName = "First name should contain only letters and spaces.";
      isValid = false;
    }

    if (!/^[a-zA-Z\s]+$/.test(formData.lastName.trim())) {
      newErrors.lastName = "Last name should contain only letters and spaces.";
      isValid = false;
    }

   if (!/^\d+$/.test(formData.phone.trim())) {
  newErrors.phone = "Phone number should contain only digits.";
  isValid = false;
}


    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email.trim())) {
      newErrors.email = "Please enter a valid email address.";
      isValid = false;
    }

    if (!formData.message.trim()) {
      newErrors.message = "Message is required.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const secretKey = process.env.REACT_APP_SECRET_KEY;
        const response = await fetch("https://littleblackstarfx.com/admin/api/send-email.php", {
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: `Bearer ${secretKey}` },
          body: JSON.stringify(formData),
        });
        const data = await response.json();
        if (data.success) {
          setResponseMessage(data.message);
          setSuccess(true);
        } else {
          setResponseMessage(data.message);
          setSuccess(false);
        }
      } catch (error) {
        setResponseMessage("An error occurred. Please try again later.");
        setSuccess(false);
      }
    }
  };

  const handleCloseMessage = () => {
    setSuccess(false);
    setResponseMessage("");
    setFormData({ firstName: "", lastName: "", phone: "", email: "", message: "" });
    setErrors({});
  };
  useEffect(() => {
    document.title = "Little Black Star Visual Effects";
  }, []);


  return (
    <div className="get-in-touch">
    <div className="overlay">
      <div className="contact-container">
        <h1 className="page-title text_center">Get in Touch</h1>
        <p>
          Contact us today to bring your vision to life with our cutting-edge solutions. <br></br>
          Let’s  create something remarkable together!
        </p>

        {responseMessage && success ? (
         <div className="success-message">
         <div dangerouslySetInnerHTML={{ __html: responseMessage }}></div>
         <button onClick={handleCloseMessage} className="close-button">
           Close
         </button>
       </div>
         
        ) : (
          <form onSubmit={handleSubmit} className="contact-form">
          <div className="row">
            <div className="col-md-6">  <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
            {errors.firstName && <span className="error">{errors.firstName}</span>}
          </div></div>
            <div className="col-md-6"> <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
            {errors.lastName && <span className="error">{errors.lastName}</span>}
          </div></div>
          </div>
        

         
          <div className="row">
            <div className="col-md-6">   <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input
              type="text"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            {errors.phone && <span className="error">{errors.phone}</span>}
          </div></div>
            <div className="col-md-6"><div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div></div>
          </div>
       

          
          <div className="row">
            <div className="col-md-12"><div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            {errors.message && <span className="error">{errors.message}</span>}
          </div></div>
            <div className="col-md-6"></div>
          </div>
          <div className="row">
            <div className="col-md-12">   <button type="submit" className="submit-button">
            Submit
          </button></div>
            <div className="col-md-6"></div>
          </div>
          

       
        </form>
        )}

        {!success && responseMessage && (
          <div className="error-message">
            <p>{responseMessage}</p>
          </div>
        )}
      </div>
      </div>
    </div>
  );
};

export default GetInTouch;
