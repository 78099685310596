// pages/VideoView.jsx
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../styles/reels.css";

const VideoView = () => {
  const { id } = useParams(); // Get video ID from the URL
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <div className="video-view-page">
      {/* Back Button */}
      <button className="back-button" onClick={handleBackClick}>
        &larr; Back
      </button>

      {/* Full-Page Video */}
      <iframe
        title={`Vimeo Video ${id}`} // Unique title for accessibility
        src={`https://player.vimeo.com/video/${id}?autoplay=1&muted=1`}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        className="full-page-video"
      ></iframe>
    </div>
  );
};

export default VideoView;
