import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/stories.css";

const Stories = () => {
  const [loading, setLoading] = useState(true);
  const [stories, setStories] = useState([]);
  const [filteredStories, setFilteredStories] = useState([]);
  const [categories, setCategories] = useState(["All"]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStories = async () => {
      try {
        const response = await fetch("https://littleblackstarfx.com/admin/api/stories.php", {
          method: "GET",
          headers: {
            Authorization: "Bearer saka8f459fb743e8bc8d5e249ce2d8f3cfdar1vifnthan8c89e67b1d4a5f4a6b8c9d0e1aravinthan",
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`API error: ${response.status} ${response.statusText}`);
        }

        const jsonResponse = await response.json();
        console.log("API Response:", jsonResponse);

        if (jsonResponse.status !== "success" || !jsonResponse.data) {
          throw new Error("No stories found");
        }

        // ✅ Save API data
        setStories(jsonResponse.data);
        setFilteredStories(jsonResponse.data);

        // ✅ Extract unique categories
        const uniqueCategories = ["All", ...new Set(jsonResponse.data.map((story) => story.category?.trim() || "Uncategorized"))];
        setCategories(uniqueCategories);
      } catch (error) {
        console.error("Error fetching stories:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStories();
  }, []);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    const filtered = category === "All"
      ? stories
      : stories.filter((story) => story.category.trim().toLowerCase() === category.trim().toLowerCase());
    setFilteredStories(filtered);
  };

  // ✅ Modify `handleClick` to navigate by slug
  const handleClick = (slug) => {
    navigate(`/stories/${slug}`);
  };
  useEffect(() => {
    document.title = "Little Black Star Visual Effects";
  }, []);

  return (
    <div className="page">
            <div className="container ">
            <div className="row">
        <div className="col-md-12 text_center">
        <h1 className="page-title text_center">Stories</h1>
          </div></div></div>
   

      <div className="category-filters">
        {categories.map((category) => (
          <button
            key={category}
            className={`filter-button ${selectedCategory === category ? "active" : ""}`}
            onClick={() => handleCategoryChange(category)}
          >
            {category}
          </button>
        ))}
      </div>

      <div className="stories-grid">
        {loading ? (
          <p>Loading stories...</p>
        ) : filteredStories.length === 0 ? (
          <p>No stories available. Try a different category.</p>
        ) : (
          filteredStories.map((story) => (
            <div key={story.slug} className="story-card" onClick={() => handleClick(story.slug)}>
              <img src={story.thumbnail} alt={story.title} className="story-thumbnail" />
              <p className="story-title">{story.title}</p>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Stories;
