import React, { useState, useEffect } from "react";
import "../styles/reels.css";

const Reels = () => {
  const [videoData, setVideoData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedType, setSelectedType] = useState("All");
  const [popupContent, setPopupContent] = useState(null);

  // ---- ADDED: Helper to detect Vimeo and extract ID ----
  const isVimeoLink = (url) => {
    return url.includes("vimeo.com");
  };

  const getVimeoId = (url) => {
    // e.g., "https://vimeo.com/123456789" => "123456789"
    return url.split("/").pop();
  };

  // ------------------------------------------------------

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      const secretKey = process.env.REACT_APP_SECRET_KEY;
      try {
        const response = await fetch("https://littleblackstarfx.com/admin/api/portfolio.php", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${secretKey}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setVideoData(data.data || data); // Adjust based on the API response structure
      } catch (error) {
        console.error("Error fetching video data:", error);
      }
    };
    fetchData();
  }, []);
  const isYouTubeLink = (url) => {
    return url.includes("youtube.com") || url.includes("youtu.be");
  };
  const getYouTubeId = (url) => {
    const match = url.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    return match ? match[1] : null;
  };

  // ---- UPDATED: handleThumbnailClick to handle Vimeo vs. MP4 ----
  const handleThumbnailClick = (item) => {
    if (item.type === "Video") {
      if (isYouTubeLink(item.file_url)) {
        // YouTube video
        const videoId = getYouTubeId(item.file_url);
        setPopupContent(
          <div className="iframe-container">
            <iframe
              src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
              allow="autoplay; encrypted-media"
              allowFullScreen
              title={item.title}
            ></iframe>
          </div>
        );
      } else if (isVimeoLink(item.file_url)) {
        // Vimeo video
        const videoId = getVimeoId(item.file_url);
        setPopupContent(
          <div className="iframe-container">
            <iframe
              src={`https://player.vimeo.com/video/${videoId}?autoplay=1`}
              allow="autoplay; fullscreen"
              allowFullScreen
              title={item.title}
            ></iframe>
          </div>
        );
      } else {
        // MP4 video
        setPopupContent(
          <video controls autoPlay className="popup-video">
            <source src={item.file_url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );
      }
    } else if (item.type === "Image") {
      setPopupContent(
        <img src={item.file_url} alt={item.title} className="popup-image2" />
      );
    }
  };
  // ------------------------------------------------------

  const closePopup = () => {
    setPopupContent(null);
  };

  const categories = ["All", "Film", "Commercials","NFTs"];
  const types = ["All", "Video", "Image"];

  const allowedNFTs = [41,46,49]; // Replace with actual IDs

  const filteredItems = Array.isArray(videoData)
    ? videoData.filter((item) => {
        const isExcludedNFT =
          item.category === "NFTs" && !allowedNFTs.includes(item.id);
  
        const matchesCategory =
          selectedCategory === "All"
            ? !isExcludedNFT
            : item.category === selectedCategory;
  
        const matchesType =
          selectedType === "All" || item.type === selectedType;
        
        return matchesCategory && matchesType;
      })
    : [];

  useEffect(() => {
    document.title = "Little Black Star Visual Effects";
  }, []);

  return (
    <div className="page">
      <div className="container pb-5">
        <div className="row">
          <div className="col-md-12 text_center">
            <h1 className="page-title text_center">Projects</h1>
          </div>
          <div className="col-md-12 text_center">
            <div className="filters">
              <div className="filter-group">
                <span className="filter_tit">Category:</span>
                {categories.map((category) => (
                  <button
                    key={category}
                    className={`filter-button ${
                      selectedCategory === category ? "active" : ""
                    }`}
                    onClick={() => setSelectedCategory(category)}
                  >
                    {category}
                  </button>
                ))}
              </div>

              <div className="filter-group">
                <span className="filter_tit">Type:</span>
                {types.map((type) => (
                  <button
                    key={type}
                    className={`filter-button ${
                      selectedType === type ? "active" : ""
                    }`}
                    onClick={() => setSelectedType(type)}
                  >
                    {type}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Masonry Gallery */}
      <div className="reels-masonry">
        {filteredItems.map((item) => (
          <div className="reel-item" key={item.id}>
            <div
              className="reel-thumbnail"
              onClick={() => handleThumbnailClick(item)}
            >
              <img
                src={item.type === "Video" ? item.thumbnail_url : item.file_url}
                alt={item.title}
                className="video-thumbnail"
              />
              {item.type === "Video" && <div className="play-icon">▶</div>}
              <div className="hover-overlay">
                <div className="hover-content">
                  <h3>{item.title}</h3>
                  <button
                    className="view-button"
                    onClick={() => handleThumbnailClick(item)}
                  >
                    {item.type === "Video" ? "Play Video" : "View Image"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Popup for Content */}
      {popupContent && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-content">
            <button className="close-popup" onClick={closePopup}>
              X
            </button>
            {popupContent}
          </div>
        </div>
      )}
    </div>
  );
};

export default Reels;
