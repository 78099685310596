// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 900px;
    margin: auto;
    position: relative;
    overflow: hidden;
  }
  
  .slider-wrapper {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
  
  .slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
    will-change: transform;
  }
  
  .slide-image {
    width: 100%; /* Image takes full width of its container */
    height: auto;
    min-height: 160px; /* Ensures min height */
    object-fit: cover;
    border-radius: 8px;
    flex-shrink: 0;
  }
  
  /* Navigation buttons */
 
 
.slider-container .prev { display: none; }
.slider-container .next { display: none; }
  @media (max-width: 480px) {
    /* Adjust for mobile devices */
    
    .slide-image {
        width: 100%; /* Image takes full width of its container */
        height: auto;
        min-height: 360px; /* Ensures min height */
        object-fit: cover;
        border-radius: 8px;
        flex-shrink: 0;
      }
  
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/MultiItemSlider.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,uBAAuB;EACzB;;EAEA;IACE,aAAa;IACb,sCAAsC;IACtC,sBAAsB;EACxB;;EAEA;IACE,WAAW,EAAE,4CAA4C;IACzD,YAAY;IACZ,iBAAiB,EAAE,uBAAuB;IAC1C,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;EAChB;;EAEA,uBAAuB;;;AAGzB,0BAA0B,aAAa,EAAE;AACzC,0BAA0B,aAAa,EAAE;EACvC;IACE,8BAA8B;;IAE9B;QACI,WAAW,EAAE,4CAA4C;QACzD,YAAY;QACZ,iBAAiB,EAAE,uBAAuB;QAC1C,iBAAiB;QACjB,kBAAkB;QAClB,cAAc;MAChB;;EAEJ","sourcesContent":[".slider-container {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    width: 100%;\r\n    max-width: 900px;\r\n    margin: auto;\r\n    position: relative;\r\n    overflow: hidden;\r\n  }\r\n  \r\n  .slider-wrapper {\r\n    width: 100%;\r\n    overflow: hidden;\r\n    display: flex;\r\n    justify-content: center;\r\n  }\r\n  \r\n  .slider {\r\n    display: flex;\r\n    transition: transform 0.5s ease-in-out;\r\n    will-change: transform;\r\n  }\r\n  \r\n  .slide-image {\r\n    width: 100%; /* Image takes full width of its container */\r\n    height: auto;\r\n    min-height: 160px; /* Ensures min height */\r\n    object-fit: cover;\r\n    border-radius: 8px;\r\n    flex-shrink: 0;\r\n  }\r\n  \r\n  /* Navigation buttons */\r\n \r\n \r\n.slider-container .prev { display: none; }\r\n.slider-container .next { display: none; }\r\n  @media (max-width: 480px) {\r\n    /* Adjust for mobile devices */\r\n    \r\n    .slide-image {\r\n        width: 100%; /* Image takes full width of its container */\r\n        height: auto;\r\n        min-height: 360px; /* Ensures min height */\r\n        object-fit: cover;\r\n        border-radius: 8px;\r\n        flex-shrink: 0;\r\n      }\r\n  \r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
