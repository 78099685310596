// App.js
import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Header from "./components/Header";
import Footer from "./components/Footer";


// Import Pages
import Home from "./pages/Home";
import Projects from "./pages/Reel";
import VideoView from "./pages/VideoView";
import Stories from "./pages/Stories";
import StoryView from "./pages/StoryView";
import AboutUs from "./pages/AboutUs";
import  Connect from "./pages/GetInTouch";
import  Squad from "./pages/Squad";
 

 

const App = () => {
  return (
    <Router>
       <ScrollToTop />
      {/* Header */}
      <Header />

      {/* Main Content */}
      <main>
 
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
     
    
          <Route path="/stories" element={<Stories />} />
 
          <Route path="/stories/:slug" element={<StoryView />} />
          <Route path="/video/:id" element={<VideoView />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/squad" element={<Squad />} />
          <Route path="/Lets-Connect" element={<Connect />} />
          
          
          x
        </Routes>
      </main>

      {/* Footer */}
      <Footer />
    </Router>
  );
};

export default App;
