import React, { useEffect, useState } from "react";
import "../styles/carousel.css";

const InfiniteCarousel = ({ images, itemWidth }) => {
  const [translateX, setTranslateX] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTranslateX((prev) => prev - itemWidth);
    }, 2000); // Change every 2 seconds

    return () => clearInterval(interval);
  }, [itemWidth]);

  // Reset translateX to create an infinite effect
  useEffect(() => {
    if (Math.abs(translateX) >= images.length * itemWidth) {
      setTranslateX(0);
    }
  }, [translateX, images.length, itemWidth]);

  return (
    <div className="carousel-container">
      <div
        className="carousel-track"
        style={{
          transform: `translateX(${translateX}px)`,
          transition: "transform 0.5s ease-in-out",
        }}
      >
        {/* Duplicate items for infinite effect */}
        {[...images, ...images].map((src, index) => (
          <div
            key={index}
            className="carousel-item"
            style={{ width: `${itemWidth}px` }}
          >
            <img src={src} alt={`Carousel item ${index}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfiniteCarousel;
