import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

const StoryView = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [story, setStory] = useState(null);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    document.title = "Little Black Star Visual Effects";
  }, []);
  useEffect(() => {
    const fetchStory = async () => {
      try {
        const response = await fetch(`https://littleblackstarfx.com/admin/api/storiesview.php?slug=${slug}`, {
          method: "GET",
          headers: {
            Authorization: "Bearer saka8f459fb743e8bc8d5e249ce2d8f3cfdar1vifnthan8c89e67b1d4a5f4a6b8c9d0e1aravinthan",
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Story not found");
        }

        const jsonResponse = await response.json();
        if (jsonResponse.status !== "success" || !jsonResponse.data) {
          throw new Error("Invalid story data");
        }

        setStory(jsonResponse.data);
      } catch (error) {
        console.error("Error fetching story:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStory();
  }, [slug]);

  if (loading) return <p>Loading story...</p>;
  if (!story) return <p>Story not found!</p>;



  return (
    <div className="story-view-page">
      <button className="back-button" onClick={() => navigate(-1)}>
        &larr; Back
      </button>
      
      <h1>{story.title}</h1>
 
      
 

      {/* ✅ Render full content as HTML */}
      <div className="story-content" dangerouslySetInnerHTML={{ __html: story.content }} />
    </div>
  );
};

export default StoryView;
