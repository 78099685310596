import React, { useState, useEffect, useCallback  } from "react";
 
import logo from "../assets/images/LBS_logo_W.png"; 
import "../styles/squad.css";

import "../styles/ImageCarousel.css";
import InfiniteCarousel from "./Carousel";
import MultiItemSlider  from "./MultiItemSlider";

 
const Squad = () => {
  // Image sources for each section
  const sectionImages = {
    sec1: [
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s1_1.jpg",
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s1_2.jpg",
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s1_3.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s1_4.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s1_5.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s1_6.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s1_7.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s1_8.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s1_9.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s1_10.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s1_11.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s1_12.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s1_13.jpg", 
      

      
    ],
    sec2: [
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s2_1.jpg",
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s2_2.jpg",  
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s2_3.jpg",  
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s2_4.jpg",  
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s2_5.jpg",  
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s2_6.jpg",  
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s2_7.jpg", 
      
    ],
    sec3: [
      
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s3_1.jpg",
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s3_2.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s3_3.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s3_4.jpg", 
      

      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s3_5.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s3_6.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s3_7.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s3_8.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s3_9.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s3_10.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s3_11.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s3_12.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s3_13.jpg", 
      
    ],
    sec4: [
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s4_1.jpg",
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s4_2.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s4_3.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s4_4.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s4_5.jpg",
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s4_6.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s4_7.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s4_8.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s4_9.jpg",
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s4_10.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s4_11.jpg", 

      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s4_12.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s4_13.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s4_14.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s4_15.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s4_16.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s4_17.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s4_18.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s4_19.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s4_20.jpg", 
      "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s4_21.jpg", 
 

    ],
    
  };
   
  const images = [
    "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s5_1.jpg",
    "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s5_2.jpg",
    "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s5_3.jpg", 
    "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s5_4.jpg", 
    "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s5_5.jpg",
    "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s5_6.jpg", 
    "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s5_7.jpg", 
    "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s5_8.jpg", 
    "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s5_9.jpg",
    "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s5_10.jpg", 
    "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s5_11.jpg", 
    "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s5_12.jpg",
    "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s5_13.jpg",
    "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s5_14.jpg",
    "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s5_15.jpg",
    "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s5_16.jpg",
    "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s5_17.jpg",
    "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s5_18.jpg",
    "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s5_19.jpg",
    "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s5_20.jpg",
    "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s5_21.jpg",
    "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s5_22.jpg",
    "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s5_23.jpg",
    "https://lbslive.s3.ap-south-1.amazonaws.com/gallery/lbs_s5_24.jpg",
 
 


 
  ];
const sec1 ="";
  // State to store the current image index for each section
  const [currentImage, setCurrentImage] = useState({
    sec1: 0,
    sec2: 0,
    sec3: 0,
    sec4: 0,
  });

  // Function to change the image for each section
  const changeImage = (section) => {
    setCurrentImage((prev) => {
      const nextImageIndex =
        prev[section] + 1 >= sectionImages[section].length ? 0 : prev[section] + 1;
      return { ...prev, [section]: nextImageIndex };
    });
  };
 const [showTransition, setShowTransition] = useState(false);
  // Use useEffect to trigger the image change every 3 seconds
  useEffect(() => {
    const intervalIds = [];

    // Set intervals for each section to change images
    Object.keys(sectionImages).forEach((section) => {
      const intervalId = setInterval(() => {
        changeImage(section);
      }, 4000); // Change every 3 seconds
      intervalIds.push(intervalId);
    });
    const timer = setTimeout(() => setShowTransition(true),1500);

    // Cleanup intervals when the component unmounts
    return () => {
      intervalIds.forEach((id) => clearInterval(id));
    };
  }, []);

 
   
  useEffect(() => {
    document.title = "Little Black Star Visual Effects";
  }, []);

  

  return (
        <div className="page_squad  ">
     
 <section className="main-banner fade in-view ">
    <div className="video-container">
  <img src="https://lbslive.s3.ap-south-1.amazonaws.com/gallery/squad.jpg" alt="LBS" className="img-fluid" width="100%"  />
  </div>
        <div
          className={`banner-content ${showTransition ? "transitioned" : ""}`}
        >
          <img
            src={logo}
            alt="LBS"
            className="banner-logo"
          />
          
        </div>
      </section>

      <div className="container ">
       
        <div className="row d-flex justify-content-center align-items-center text-center my-5 py-5">
           <div className="col-md-12 text_center">
             <h1  className="squad_tit">The Squad</h1>
             <p> We cultivate a fun and positive work environment where creativity thrives. Our inclusive culture values every opinion and idea, allowing us to tackle VFX challenges collaboratively. With access to top-tier tools and the freedom to explore, our artists are empowered to unleash their full potential in a supportive and inspiring atmosphere.</p>
          </div>
        </div>
        <div className="row d-flex justify-content-center align-items-center  my-5 py-5">
          
          <div className="col-lg-6 col-md-12  text-xl-end  text-lg-end  text-md-center  text-sm-center    ">
            <h4 className="squad_tit">Creative & <br></br> Fun Environment</h4>
            <p>On the 10th of each month, we host <strong>Fun Activity Day</strong>, packed with outdoor games (like Foosball, Tug of War, Badminton and more)) and indoor challenges (Werewolf Mafia, Clay Sculpting, Guess The Emoji, etc), all designed to bring the team closer and spark some fun!</p>
          </div>
          <div className="col-lg-6 col-md-12  text_center">
              <img
              src={sectionImages.sec1[currentImage.sec1]}
              alt="Next"
              className="SquadImg"
              />
          </div>
        </div>
        <div className="row d-flex justify-content-center align-items-center text-center my-5 py-5">
        <div className="col-md-12 col-sm-12 d-lg-none  text-md-center  text-sm-center">
           <h4 className="squad_tit">Knowledge & Learning</h4>
            <p> Every 20th, we hold <strong>workshops and training</strong> that focus on both personal and professional growth, featuring industry experts and internal knowledge sharing to keep our team on top of the latest trends.</p>
          </div>
          <div className="col-lg-6 col-md-12  text_center">
              <img
              src={sectionImages.sec2[currentImage.sec2]}
              alt="Next"
              className="SquadImg"
              />
          </div>
          <div className="col-lg-6 d-none d-lg-block text-lg-start">
           <h4 className="squad_tit">Knowledge & Learning</h4>
            <p> Every 20th, we hold <strong>workshops and training</strong> that focus on both personal and professional growth, featuring industry experts and internal knowledge sharing to keep our team on top of the latest trends.</p>
          </div>
        </div>
    
        <div className="row d-flex justify-content-center align-items-center text-center my-5 py-5">
           <div className="col-lg-6 col-md-12 text-xl-end  text-lg-end  text-md-center  text-sm-center  ">
            <h4 className="squad_tit">Recognition & Appreciation</h4>
              <p> <strong> Monthly Wrap</strong>, held on the last day of the month, we reflect on our progress, plan ahead, and celebrate outstanding performance with team lunches and awards like Employee of the Month and Star Performer of the Month.</p>
          </div>
          <div className="col-lg-6 col-md-12  text_center">
          <img
              src={sectionImages.sec3[currentImage.sec3]}
              alt="Next"
              className="SquadImg"
            />

          </div>
        </div>
      
        <div className="row d-flex justify-content-center align-items-center text-center my-5 py-5">
        <div className="col-md-12 col-sm-12 d-lg-none  text-md-center  text-sm-center">
              <h4 className="squad_tit">Exposure</h4>
              <p>
              We ensure our team stays connected to the industry by attending conferences like <strong>MIPCOM, SIGGRAPH, ATF, TOKEN 2049, VFX Summit India Joy</strong>, and <strong>VEGAS Fest</strong>.These events provide opportunities to network, showcase our work, participate in fun events, and gain valuable insights from industry leaders.
              </p>
           </div>

          <div className="col-lg-6 col-md-12 text_center">
     
            <img
              src={sectionImages.sec4[currentImage.sec4]}
              alt="Next"
              className="SquadImg"
            />

          </div>
          <div className="col-lg-6 col-md-12 d-none d-lg-block ">
              <h4 className="squad_tit">Exposure</h4>
              <p>
              We ensure our team stays connected to the industry by attending conferences like <strong>MIPCOM, SIGGRAPH, ATF, TOKEN 2049, VFX Summit India Joy</strong>, and <strong>VEGAS Fest</strong>.These events provide opportunities to network, showcase our work, participate in fun events, and gain valuable insights from industry leaders.
              </p>
           </div>
        </div>
      </div> 
      <div className="container-fluid">
        <div className="row d-flex   text-center mb-5 ">
          <div className="col-lg-12 col-md-12 pb-5  text_center">
          
            <p>
            We also love to unwind with <strong>occasional partie</strong>s at<br></br> fun venues and <strong>movie nights</strong>, making sure we relax and bond as a team.</p>
            <h4>At Little Black Star, we believe in <br></br>growing, learning, and having fun together!</h4>
          </div>  
        </div>  
        <div className="min-h-screen flex items-center justify-center bg-gray-100    d-xll-none d-xl-none  d-lg-none  d-md-block  d-sm-block  d-xs-block"  >
          <MultiItemSlider images={images} />
        </div>
       

        <div className="row   text-center mb-5 "> 
        <div className="col-lg-12 col-md-12    text_center 		d-none  d-lg-block  d-xl-block  d-xll-block "  >
        <InfiniteCarousel images={images} itemWidth={450} />
          </div>
           <div className="col-lg-12 col-md-12 pt-5   text_center">
        
            <p>
            We’re always on the lookout for talented and passionate individuals to join <br></br>our creative squad at Little Black Star Visual Effects. <br></br>If you're passionate about VFX and want to work in a place where your ideas matter,<br></br> we want to hear from you. </p>
            <h2>Apply now and let’s create something awesome together!</h2><br></br>

            <a href="https://littleblackstar.keka.com/careers/" target="_blank" class="apply-button mt-5"> Apply Now </a>
        
          </div>
        </div>  
        </div>   </div> 
)};

export default Squad;
