import React, { useState, useEffect } from "react";
 

import plus from "../assets/images/plus.png"; 

import prevImg from "../assets/images/prev.png"; // Image for prev button
import nextImg from "../assets/images/next.png"; // Image for next button

const AboutUs = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedMember, setSelectedMember] = useState(null); // State for selected member

  const teamMembers = [
    { 
      id: 1,
      name: "Sai Krishna Vudatha", 
      role: "Co-Founder", 
      role2: "VFX Supervisor", 
      role3: "Founder | VFX Supervisor", 
      image: "https://lbslive.s3.ap-south-1.amazonaws.com/team/KRISHNA.jpg", 
      bio: "Krishna is the visionary force behind FX, CGI supervision, and pipeline development at Little Black Star Visual Effects. As the Founder, he combines technical expertise with strategic foresight to ensure seamless operations and exceptional results.<br><br>   A leader committed to innovation and quality, Krishna drives the studio’s growth and strengthens its reputation in the VFX industry. He focuses on refining workflows, managing complex CGI processes, and fostering creative excellence in every project.<br><br>  Krishna cultivates a collaborative environment that empowers the team to innovate and thrive, keeping Little Black Star at the forefront of the VFX world.  " },
    { 
      id: 2,
      name: "Meghana Tirumalasetty", 
      role: "Co-Founder", 
      role2: "Head of Business", 
      role3: "Co-Founder | Business Lead | Web3 Integration | Strategic Vision", 
      image: "https://lbslive.s3.ap-south-1.amazonaws.com/team/MEGHANA2.jpg", 
      bio: "Meghana drives business operations and development at Little Black Star Visual Effects Studio. As the studio’s primary spokesperson, she builds strong relationships with clients, partners, and stakeholders, fueling its growth and reputation.<br><br> A visionary leader, Meghana is passionate about innovation and exploring opportunities to expand the studio’s reach. Her expertise in business development helps refine strategies, forge collaborations, and keep Little Black Star competitive in the fast-paced VFX industry. <br><br>With a focus on emerging opportunities, including Web3, Meghana guides the studio into a transformative era, ensuring it remains at the forefront of innovation. Her vision continues to shape the studio’s bright future." 
    },
    
    { 
      id: 4,
      name: "Rajesh Lukka", 
      role: "Show Producer",  
      role2: "Creative Director", 
      role3: "Show Producer | Creative Director | Project Leadership", 
      image: "https://lbslive.s3.ap-south-1.amazonaws.com/team/Rajesh.jpg", 
      bio: "Rajesh leads production at Little Black Star Visual Effects Studio, overseeing FX and CGI projects from start to finish. As Show Producer and Creative Director, he manages everything from the bidding process to meeting deadlines and maintaining quality throughout production. <br><br>With a solid understanding of visual effects production and a creative mindset, Rajesh handles challenges with ease, ensuring the team stays aligned with the project’s vision. His leadership and strategic approach bring out the best in the team, delivering standout results. <br><br>Rajesh’s proactive approach ensures smooth production, reinforcing Little Black Star’s reputation for excellence in both creativity and execution. His ability to balance creativity with technical precision is key to the studio’s success." 
    },
    { 
      id: 5,
      name: "Ameena Khandker", 
      role: "Business Operations",  
      role2: "Marketing Head", 
      role3: "Business Operations | Head of Marketing | Web3 Project Lead", 
      image: "https://lbslive.s3.ap-south-1.amazonaws.com/team/Ameena.jpg", 
      bio: "Ameena is the Marketing and Operations Maverick at Little Black Star Visual Effects Studio, seamlessly blending creativity with strategic thinking. As head of both marketing and business operations, she strengthens the studio’s brand presence while ensuring smooth and efficient day-to-day operations. <br><br>Ameena also plays a key role in managing our in-house Web3 project, Apes Far From Home, driving its growth with her innovative marketing approach and keeping us ahead in the evolving Web3 space. <br><br>She oversees HR, employee management, and ensures company policies are followed, maintaining a positive work culture. With her hands-on leadership and passion for innovation, Ameena keeps Little Black Star agile, creative, and ready for the future." 
    },{ 
      id: 3,
      name: "Ravi Tadiparthi", 
      role: "Head of Studio",  
      role2: "Finance Head", 
      role3: "Head of Studio | Finance Lead | Strategic Guidance", 
      image: "https://lbslive.s3.ap-south-1.amazonaws.com/team/RAVI.jpg", 
      bio: "Ravi is the strategic mind behind the financial operations at Little Black Star Visual Effects Studio, bringing over 30 years of experience across diverse industries. His business acumen ensures precise financial planning, budgeting, and forecasting, keeping the studio secure and poised for growth.<br><br> Beyond finance, Ravi’s broad business experience informs decisions that shape the studio’s long-term vision. His leadership balances financial precision with strategic growth, helping us stay competitive and adapt to the evolving VFX industry. <br><br>With his sharp insights and proactive approach, Ravi plays a key role in guiding Little Black Star through both challenges and opportunities, ensuring continued success." 
    },
  ];

  const nextMember = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % teamMembers.length);
  };

  const prevMember = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + teamMembers.length) % teamMembers.length);
  };
  const handleMemberClick = (member) => {
    setSelectedMember(member);
  };
  const closePopup = () => {
    setSelectedMember(null); // Close the popup
  };
  // Auto-scroll effect
 

   useEffect(() => {
    if (selectedMember === null) {
      const interval = setInterval(nextMember, 7000); // Change active member every 7 seconds
      return () => clearInterval(interval); // Cleanup interval on unmount or popup open
    }
  }, [selectedMember]); // Rerun when `selectedMember` changes
  useEffect(() => {
    document.title = "Little Black Star Visual Effects";
  }, []);

  return (
    <div className="page">
      <div className="container pb-5 p-0">
      <div className="row">
        <div className="col-md-6 col-sm-12  offset-md-3 text_center">
          <h1 class="page-title text_center">About Us</h1>
          <p>
          At Little Black Star VFX (LBS), we’re a creative studio full of lively, passionate storytellers. Every shot, frame, and pixel we touch gets a little piece of our heart because making magic is just what we do!



          </p>
          <p>
          We bring bold ideas to life—creating jaw-dropping visuals for Feature Films, Commercials, Episodics, Gaming trailers, Music Videos, NFTs and more. Whether it’s previsualization, dynamic FX, or lighting that makes your project pop, we’ve got the skills to make every frame unforgettable.
          </p>
          <p>
          Let’s team up and make something amazing together—your next big idea deserves a little LBS magic! ✨
          </p>
        </div>
        </div></div>
        {/* Team Member Carousel */}
        <div className="container ">
        <div className="row pt-5">
        <div id="teamCarousel" className="carousel slide" data-bs-ride="carousel" data-bs-interval="false">
             {/* Prev and Next Buttons with Images */}
             <button className="carousel-control-prev" type="button" onClick={prevMember}>
            <img src={prevImg} alt="Previous" className="carousel-control-prev-icon" />
            <span className="visually-hidden"> </span>
          </button>
          <div className="carousel-inner">
            <div className="carousel-item2 active">
              <div className="team-members">
                {/* Previous Member */}
                <div className="team-member blurred"   >
                  
                  <img src={teamMembers[(activeIndex - 1 + teamMembers.length) % teamMembers.length].image} alt="Previous" className="team-image2" />
                  <h5>{teamMembers[(activeIndex - 1 + teamMembers.length) % teamMembers.length].name}</h5>
                  <h6>{teamMembers[(activeIndex - 1 + teamMembers.length) % teamMembers.length].role}</h6>
                  <h6>{teamMembers[(activeIndex - 1 + teamMembers.length) % teamMembers.length].role2}</h6>
                </div>
                {/* Active Member */}
                <div   className="team-member active"        >
                
                
                  <img src={teamMembers[activeIndex].image} alt="Active" className="team-image2" />
                  <img src={plus} alt="Active" className="plusicon"  onClick={() => setSelectedMember(teamMembers[activeIndex].id)}  />

                  <h5>{teamMembers[activeIndex].name}</h5>
                  <h6>{teamMembers[activeIndex].role}</h6>
                  <h6>{teamMembers[activeIndex].role2}</h6>
                </div>
                {/* Next Member */}
                <div className="team-member blurred"  >
        
                  <img src={teamMembers[(activeIndex + 1) % teamMembers.length].image} alt="Next" className="team-image2" />
            

                  <h5>{teamMembers[(activeIndex + 1) % teamMembers.length].name}</h5>
                  <h6>{teamMembers[(activeIndex + 1) % teamMembers.length].role}</h6>
                  <h6>{teamMembers[(activeIndex + 1) % teamMembers.length].role2}</h6>
                </div>
              </div>
            </div>
          </div>

       
          <button className="carousel-control-next" type="button" onClick={nextMember}>
            <img src={nextImg} alt="Next" className="carousel-control-next-icon" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
     {/* Popup for Member Details */}
      {selectedMember && (
        <div className="popup">
          <div className="popup-content2 ">
            <button className="close-btn" onClick={closePopup}>✕</button>
            <img src={teamMembers[activeIndex].image} alt="Active" className="team-image2" />
            <h5>{teamMembers[activeIndex].name}</h5>
                  <h6>  {teamMembers[activeIndex].role3}</h6>
                
                  <p dangerouslySetInnerHTML={{ __html: teamMembers[activeIndex].bio }} />
          </div>
        </div>
      )}
    </div>
  );
};

export default AboutUs;
